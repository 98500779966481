<!--
  * @desc:
  * @author: 肖
  * @create_date: 2021/6/14
  * @BSD
-->
<template>
  <div class="page-wrap pt188">
    <!-- 顶部 -->
    <top-bar-arrow>
      <div slot="title" class="title-image">
        商品物流详情
      </div>
    </top-bar-arrow>
    <div class="main">
      <template v-for="(item, index) in orderInfo.product_list">
        <div class="product-order-item box-shadow" :key="index">
          <div class="left">
            <img :src="item.small_image_url" mode="" />
          </div>
          <div class="center">
            <div class="line1">{{ item.product_name }}</div>
          </div>
          <div class="right">
            <div class="text">{{ getStatus(item.logistics_order_status) }}</div>
          </div>
        </div>
      </template>
      <div class="address-edit-wrap box-shadow">
        <div class="left">
          <div class="line1">{{ orderInfo.pcr_address }}</div>
          <div class="line2">{{ orderInfo.address }}</div>
          <div class="line3">
            <div class="text">{{ orderInfo.contacts }}</div>
            <div class="text">{{ orderInfo.contact_phone }}</div>
          </div>
        </div>
        <div class="right">
          <div class="btn-normal-small" @click.stop="gotoEdit">修改</div>
        </div>
      </div>
      <div class="order-basic-info box-shadow">
        <div class="title">订单信息</div>
        <div class="content">
          <div class="line">
            <div class="left">订单号</div>
            <div class="right">{{ orderInfo.logistics_order_no }}</div>
          </div>
          <div class="line">
            <div class="left">礼盒开启时间</div>
            <div class="right">{{ orderInfo.create_time }}</div>
          </div>
          <div class="line">
            <div class="left">商品签发时间</div>
            <div class="right">{{ orderInfo.update_time }}</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import topBarArrow from '../../components/top-bar-arrow'
  import { getOrderInfo } from '@/common/api/express.js'
  export default {
    components: {
      topBarArrow
    },
    data() {
      return {
        logistics_order_no: '',
        orderInfo: {}
      }
    },
    mounted() {
      this.logistics_order_no = this.$route.params.logistics_order_no
      this.init()
    },
    activated(){
    },
    methods: {
      getStatus (status) {
        let str = ''
        switch (status){
          case 0:
            str = '待发货'
            break;
          case 1:
            str = '已发货'
            break;
          case 2:
            str = '换货中'
            break;
          case 3:
            str = '订单异常'
            break;
        }
        return str
      },
      init () {
        getOrderInfo({
          data: {
            logistics_order_no: this.logistics_order_no
          },
          success: res => {
            if (res.code == 200) {
              this.orderInfo = res.data
            }
          }
        })
      },
      gotoEdit () {
        this.$router.push({
          name: 'addressEdit',
          params: {
            logistics_order_no: this.logistics_order_no
          }
        })
      },
    }
  }
</script>

<style lang="scss" scoped>
  @import './expressDetailWaitting.scss'
</style>

